import type { SerializeFrom } from '@remix-run/node';
import { useRouteLoaderData } from '@remix-run/react';
import type { loader as rootLoader } from '~/root.tsx';

export type RootLoaderData = SerializeFrom<typeof rootLoader>;

export function useRootLoaderData() {
  const data = useRouteLoaderData(`root`) as RootLoaderData;

  return data;
}
