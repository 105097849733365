import { Link, useNavigate } from '@remix-run/react';
import ListSVG from '~/assets/svg/list';
import SearchSVG from '~/assets/svg/search';
import WaypointSVG from '~/assets/svg/waypoint';

export default function FormStepper({ step }: { step: number }) {
  const navigate = useNavigate();
  return (
    <ul id="stepper" className="flex justify-center px-4 pb-8">
      <li className={`${step === 0 && 'selected'}`}>
        <Link to="/" className="mx-3 flex items-center font-bold">
          <SearchSVG />
          Search
        </Link>
      </li>
      <li
        className={`mx-3 flex items-center font-bold ${step === 1 && 'selected'}`}
      >
        <button
          onClick={() => navigate(-1)}
          className="mx-3 flex items-center font-bold"
          disabled={step !== 2}
        >
          <WaypointSVG />
          Choose
        </button>
      </li>
      <li
        className={`mx-3 flex items-center font-bold ${step === 2 && 'selected'}`}
      >
        <ListSVG />
        Quotes
      </li>
    </ul>
  );
}
